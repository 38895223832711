<style lang="scss" scoped>
.page-terminal-logs {}
</style>

<template>
    <div class="page-terminal-logs">
        <div class="toolbars mrgb5">
            <div class="buttons mrgb5">
                <div class="fr">
                    <el-input class="c-el-input mrgr5 mrgb5" placeholder="输入关键字搜索" clearable v-model="filter.keywords" size="medium"></el-input>
                    <el-select class="mrgr5 mrgb5" v-model="filter.reasons" placeholder="请选择" multiple size="medium">
                        <el-option v-for="item in reasonOptions" :key="item.value" :label="item.name" :value="item.value"></el-option>
                    </el-select>
                    <el-button class="mrgb5" type="primary" @click="_search()" size="medium">搜索</el-button>
                </div>
                <div class="clearb"></div>
            </div>
        </div>
        <div>
            <el-table :data="tableData" border fit highlight-current-row row-key="id" @sort-change="sortChange">
                <el-table-column prop="terminalId" label="输液宝ID" width="160">
                    <template slot-scope="scope">
                    <div>{{scope.row.terminalId}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="reasonDesc" label="类型" width="100"></el-table-column>
                <el-table-column prop="fromStatusDesc" label="之前状态" width="100"></el-table-column>
                <el-table-column prop="toStatusDesc" label="之后状态" width="100"></el-table-column>
                <el-table-column prop="deviceId" label="相关设备"></el-table-column>
                <el-table-column prop="orderId" label="相关订单"></el-table-column>
                <el-table-column prop="remark" label="备注"></el-table-column>
                <el-table-column prop="creationTime" label="创建时间" sortable="custom"></el-table-column>
            </el-table>
            <div class="pagination-container" v-if="isPagination">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" 
                    :current-page="filter.page" :page-sizes="appPageSizes" :page-size="filter.size" 
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import enumConfigs from "@/mixins/enumConfigs"
import * as funDevice from "@/api/device/index"
export default {
    name: "pageTerminalLogs",
    mixins: [enumConfigs],
    data() {
        return {
            tableItems: {},
            tableData: [],
            total: null,
            isPagination: false,
            filter: {
                page: 1,
                size: window.$common.appPageSize,
                keywords: "",
                sortDirection: 0,
                sortField: null,
                reasons: []
            }
        }
    },
    computed: {
        reasonOptions() {
            return  this.arrDeviceTerminalStatusChangeReason
        }
    },
    activated() {
        if (this.$route.query.terminalId) {
            this.filter.keywords = this.$route.query.terminalId
        }
        this.getDataList()
    },
    created() {
        if (this.$route.query.terminalId) {
            this.filter.keywords = this.$route.query.terminalId
        }
        this.getDataList()
    },
    methods: {
        handleSizeChange(val) {
            this.filter.size = val
            this.getDataList()
        },
        handleCurrentChange(val) {
            this.filter.page = val
            this.getDataList()
        },
        async getDataList() {
            window.$common.fullLoading()
            funDevice.GetTerminalLogs(this.filter).then(response => {
                window.$common.closeFullLoading()
                this.tableItems = response
                this.total = response.totalCount
                this.isPagination = response.totalCount > 0 || false
                if (response && response.items && response.items.length > 0) {
                    this.tableData = response.items
                } else {
                    this.tableData = []
                }
            })
        },
        async sortChange(e) {
            if (e.order) {
                this.filter.sortField = e.prop
                if (e.order == "ascending") {
                    this.filter.sortDirection = 0
                } else {
                    this.filter.sortDirection = 1
                }
            } else {
                this.filter.sortDirection = null
                this.filter.sortField = null
            }
            this._search()
        },
        _search() {
            this.filter.page = 1
            this.getDataList()
        }
    }
}
</script>